<template>
  <Panel :isActive="isActive" class="marker-panel">
    <PanelTitle
      :data="marker"
      :markerIcon="markerTypeIcon"
      @close="cancel">
    </PanelTitle>

    <PanelContent>
      <Fragment v-if="marker && !marker.editable">
        <Paragraph class="has-text-grey is-marginless">
          <small>{{ location.lat }}, {{ location.lng }}</small>
        </Paragraph>

        <Paragraph
          v-if="marker.note.length > 0"
          class="text-is-preformatted">
          {{ marker.note }}
        </Paragraph>

        <i v-else class="has-text-grey">{{ $t('map.general.noNote') }}</i>

        <Button
          class="is-fullwidth is-light push-top"
          icon="icon-edit"
          :title="$t('general.edit')"
          @click="edit">
        </Button>
      </Fragment>

      <Fragment v-if="marker && marker.editable">
        <Form @submit="submit">
          <FormField v-if="canEdit" :label="$t('map.general.type')">
            <Dropdown :text="$t('map.markers.' + marker.type)" :image="markerTypeIcon" :disabled="isLoading">
              <DropdownItem v-for="m in selectableMarkers" :key="m.id" @click="select(m)">
                <span v-if="m && m.icon !== undefined" class="icon is-small push-right-xxs">
                  <img :src="m.icon" class="align-icon" />
                </span>

                <span>{{ m.name }}</span>
              </DropdownItem>
            </Dropdown>
          </FormField>

          <FormField :label="$t('general.name')">
            <TextInputField
              v-model="marker.name"
              :disabled="isLoading"
              :placeholder="$t('general.name')"
              :valid="isNameValid ? '' : 'invalid'"
              @input="onNameChanged">
            </TextInputField>

            <HelpText
              v-if="!isNameValid"
              type="danger"
              :text="$t('map.nameMissing')">
            </HelpText>
          </FormField>

          <FormField v-if="setCustomTypeAndColor" :label="$t('map.marker.customType')">
            <Dropdown :text="marker.customType" :disabled="isLoading">
              <DropdownItem v-for="customType in availableCustomTypes" :key="customType" @click="selectCustomType(customType)">
                <span>{{ customType }}</span>
              </DropdownItem>
            </Dropdown>

            <HelpText
              v-if="!isCustomTypeValid"
              type="danger"
              :text="$t('map.marker.customTypeMissing')">
            </HelpText>
          </FormField>

          <FormField v-if="setCustomTypeAndColor" :label="$t('map.marker.customColor')">
            <Alert v-if="isIE" type="info" :message="$t('map.colorPickerNotSupportedMessage')" />

            <ColorInputField
              v-else
              v-model="marker.customColor"
              :disabled="isLoading"
              :placeholder="$t('general.color')">
            </ColorInputField>
          </FormField>

          <FormField label="Position">
            <div class="columns">
              <div class="column">
                <TextInputField
                  v-model="editedLocation.lat"
                  placeholder="Latitude"
                  :disabled="isLoading"
                  :valid="isLatitudeValid ? '' : 'invalid'"
                  @input="onLocationChanged">
                </TextInputField>

                <HelpText
                  v-if="!isLatitudeValid"
                  type="danger"
                  :text="$t('map.invalidCoordinate')">
                </HelpText>
              </div>

              <div class="column">
                <TextInputField
                  v-model="editedLocation.lng"
                  placeholder="Longitude"
                  :disabled="isLoading"
                  :valid="isLongitudeValid ? '' : 'invalid'"
                  @input="onLocationChanged">
                </TextInputField>

                <HelpText
                  v-if="!isLongitudeValid"
                  type="danger"
                  :text="$t('map.invalidCoordinate')">
                </HelpText>
              </div>
            </div>
          </FormField>

          <FormField :label="$t('map.general.note')">
            <TextArea v-model="marker.note" :disabled="isLoading" />
          </FormField>

          <FormField v-if="marker.id && createdBy !== null" :control="false">
            <small>{{ $t('map.general.createdAt', { date: createdAt, name: createdBy.getFullName() }) }}</small>
          </FormField>

          <ButtonGroup class="push-top-xl">
            <Button
              type="primary"
              :loading="isSaving"
              :disabled="!isValid || isRemoving"
              :title="$t('general.save')"
              @click="submit">
            </Button>

            <Button
              :disabled="isLoading"
              :title="$t('general.cancel')"
              @click="cancel">
            </Button>

            <Button
              v-if="marker.id"
              type="danger"
              :loading="isRemoving"
              :disabled="isSaving"
              icon="icon-trash"
              @click="confirmRemove">
            </Button>
          </ButtonGroup>
        </Form>
      </Fragment>
    </PanelContent>
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex'
import { eventManager } from '@/main'
import { markers, getMarkerTypeIconUrl } from '@/api/map/markers/markers'

const STATE_DEFAULT = ''
const STATE_SAVING = 'saving'
const STATE_REMOVING = 'removing'

export default {
  data () {
    return {
      huntAreaId: this.$route.params.huntAreaId,
      nameIsDirty: false,

      state: STATE_DEFAULT,

      createdBy: null,
      original: {
        name: '',
        note: '',
        type: '',
        location: {}
      },

      validation: {
        name: null,
        lat: null,
        lng: null
      },

      // Must be stored as a string for validation, and will then be converted to a number when passed to the marker
      editedLocation: {
        lat: '0',
        lng: '0'
      }
    }
  },

  computed: {
    ...mapGetters({
      marker: 'map/markers/selected'
    }),

    isActive () {
      return this.marker !== undefined
    },

    canEdit () {
      const unallowed = ['after_search', 'observation', 'kill', 'poo', 'tracks']
      return !unallowed.some(type => type === this.marker.type)
    },

    selectableMarkers () {
      const markerTypes = markers()
      return markerTypes.filter(marker => !['after_search', 'observation', 'kill', 'poo', 'tracks', 'hunter', 'hunter_safe', 'hunter_off', 'dog', 'dog_off'].includes(marker.type))
    },

    location () {
      return {
        lat: this.marker.location.lat.toFixed(5),
        lng: this.marker.location.lng.toFixed(5)
      }
    },

    isSaving () {
      return this.state === STATE_SAVING
    },

    isLoading () {
      return this.isSaving || this.isRemoving
    },

    isRemoving () {
      return this.state === STATE_REMOVING
    },

    markerTypeIcon () {
      return this.marker && this.marker.type && getMarkerTypeIconUrl(this.marker.type)
    },

    createdAt () {
      return this.marker && this.$dayjs(this.marker.createdAt).format('D MMMM YYYY, HH:mm')
    },

    isValid () {
      return this.isNameValid && this.isLatitudeValid && this.isLongitudeValid && this.isCustomTypeValid
    },

    isNameValid () {
      return this.marker.name.length > 0
    },

    isLatitudeValid () {
      return this.editedLocation.lat.length > 0 && this.isLocationValid(this.editedLocation.lat)
    },

    isLongitudeValid () {
      return this.editedLocation.lng.length > 0 && this.isLocationValid(this.editedLocation.lng)
    },

    setCustomTypeAndColor () {
      return this.marker.type === 'custom'
    },

    isCustomTypeValid () {
      return !this.setCustomTypeAndColor || (this.marker.customType && this.marker.customType.length > 0)
    },

    availableCustomTypes () {
      return ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    }
  },

  methods: {
    close () {
      this.$store.dispatch('map/markers/select', null)
      this.$router.push(`/area/${this.huntAreaId}/map`)
    },

    async edit () {
      this.original.name = this.marker.name
      this.original.note = this.marker.note
      this.original.type = this.marker.type
      this.original.location = this.marker.location
      this.original.customType = this.marker.customType
      this.original.customColor = this.marker.customColor

      this.editedLocation = this.location

      this.marker.editable = true

      if (this.marker.createdBy) {
        // This code must be executed last to prevent any delays in the UI.
        // On failure we just fail silently and don't show the label.
        this.createdBy = await this.$store.dispatch('user/read', this.marker.createdBy)
      }
    },

    cancel () {
      if (!this.marker) return

      if (this.marker.editable) {
        this.marker.name = this.original.name
        this.marker.note = this.original.note
        this.marker.type = this.original.type
        this.marker.location = this.original.location
        this.marker.customType = this.original.customType
        this.marker.customColor = this.original.customColor
        this.marker.editable = false

        this.createdBy = ''
        this.state = STATE_DEFAULT
      }

      this.close()
    },

    async submit () {
      if (!this.setCustomTypeAndColor) {
        this.marker.customType = null
        this.marker.customColor = null
      }

      this.state = STATE_SAVING

      try {
        await this.$store.dispatch('map/markers/update', {
          huntAreaId: this.huntAreaId,
          marker: this.marker
        })

        this.marker.editable = false
        this.close()
      } catch (error) {
        this.$notification.danger(this.$t('general.saveFailedErrorMessage'))
      } finally {
        this.state = STATE_DEFAULT
      }
    },

    async confirmRemove () {
      try {
        const response = await this.$dialog.confirm({
          title: this.$t('general.remove'),
          message: this.$t('general.reallyRemove', { name: this.marker.name }),
          cancel: this.$t('general.cancel'),
          ok: this.$t('general.remove')
        })

        if (response.ok) {
          await this.remove()
          this.cancel()
        }
      } catch (error) {
        this.$notification.danger(this.$t('general.saveFailedErrorMessage'))
      }
    },

    async remove () {
      this.state = STATE_REMOVING

      try {
        await this.$store.dispatch('map/markers/delete', {
          huntAreaId: this.huntAreaId,
          marker: this.marker
        })

        this.marker.visible = false
        eventManager.$emit('boundary:fitBounds')
      } finally {
        this.state = STATE_DEFAULT
      }
    },

    select (marker) {
      this.setSelected(marker)
    },

    setSelected (item) {
      if (!this.nameIsDirty) {
        this.marker.name = `${this.$t('map.markers.' + item.type)} ${(this.getMarkerCount(item.type) + 1)}`
      }

      this.marker.type = item.type
    },

    selectCustomType (customType) {
      this.marker.customType = customType
    },

    async getOwner () {
      try {
        const owner = await this.$store.dispatch('user/read', this.marker.createdBy)
        return owner
      } catch (error) {
        // do nothing
      }
    },

    getMarkerCount (markerType) {
      const markers = this.$store.getters['map/markers/markers']
      return markers.filter(marker => marker.type === markerType).length
    },

    onNameChanged () {
      this.nameIsDirty = true
    },

    isLocationValid (value) {
      const regex = /^-?\d+(\.\d{1,10})?$/
      return regex.test(value)
    },

    onLocationChanged () {
      if (this.isLatitudeValid && this.isLongitudeValid) {
        this.marker.location = {
          lat: parseFloat(this.editedLocation.lat),
          lng: parseFloat(this.editedLocation.lng)
        }
      } else {
        this.marker.location = this.original.location
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.align-icon {
  margin-top: 3px;
}

.marker-panel {
  overflow: auto;
}
</style>
